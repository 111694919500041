import {
  Button,
  ButtonTypes,
  ButtonVariants,
  Vendors,
} from '@comptia-sso/core';
import { FormEventHandler, ReactElement } from 'react';

// Components.
import { SearchFormTextField } from 'components';

// Styles.
import styles from './AccessKeySearchForm.module.scss';
import classNames from 'classnames';

interface IAccessKeySearchFormProps {
  hasSearched: boolean;
  isDirty: boolean;
  onClear: VoidFunction;
  onSubmit: FormEventHandler;
}

export const AccessKeySearchForm = (
  props: IAccessKeySearchFormProps,
): ReactElement => {
  const { hasSearched, isDirty, onClear, onSubmit } = props;

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1>Search Access Keys</h1>
      </header>
      <form
        aria-label="search access keys"
        className={styles.grid}
        noValidate
        onSubmit={onSubmit}
      >
        <SearchFormTextField label="Access Key" name="accessKey" />
        <SearchFormTextField label="Product Title" name="productTitle" />
        <SearchFormTextField label="Vendor" name="vendor" select>
          <option value={Vendors.BenchPrep}>
            {Vendors.BenchPrep.charAt(0).toUpperCase() +
              Vendors.BenchPrep.toLowerCase().slice(1)}
          </option>
          <option value={Vendors.Gutenberg}>
            {Vendors.Gutenberg.charAt(0).toUpperCase() +
              Vendors.Gutenberg.toLowerCase().slice(1)}
          </option>
          <option value={Vendors.Populi}>
            {Vendors.Populi.charAt(0).toUpperCase() +
              Vendors.Populi.toLowerCase().slice(1)}
          </option>
          <option value={Vendors.Skillable}>
            {Vendors.Skillable.charAt(0).toUpperCase() +
              Vendors.Skillable.toLowerCase().slice(1)}
          </option>
        </SearchFormTextField>
        <div className={classNames(styles.grid, styles.buttons)}>
          <Button
            className={styles.button}
            disabled={!hasSearched && !isDirty}
            type={ButtonTypes.Submit}
          >
            Search
          </Button>
          {(isDirty || hasSearched) && (
            <Button
              className={styles.button}
              onClick={onClear}
              variant={ButtonVariants.Secondary}
            >
              Clear Search
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};
