import {
  IEntitlement,
  IError,
  LoadingOverlay,
  TextFieldTypes,
  VendorNames,
  Vendors,
  getEnumKeyByEnumValue,
  useToast,
} from '@comptia-sso/core';
import { useFormik } from 'formik';
import { ReactElement, useState } from 'react';

// Components.
import {
  Card,
  Detail,
  EditableCalendar,
  EditableContent,
  EditableDetail,
  EditableSwitch,
} from 'components';

// Hooks.
import { useUpdateAccessKey } from 'hooks';

// Styles.
import styles from './AccessKeyDetails.module.scss';

// Types.
import { IAccount } from 'types';

// Validations.
import { validationSchema } from './validationSchema';

interface IAccessKeyDetailsProps {
  accessKey: IEntitlement;
  account?: IAccount;
}

export const AccessKeyDetails = (
  props: IAccessKeyDetailsProps,
): ReactElement => {
  const { accessKey, account } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [popToast] = useToast();
  const [updateAccessKey] = useUpdateAccessKey(accessKey.id);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      accessKey: accessKey?.accessKey || '',
      active: accessKey?.active ?? false,
      creationDate: accessKey.creationDate || '',
      expirationDate: accessKey?.expirationDate || '',
      isCertmasterCE: accessKey?.isCertmasterCE ?? false,
      issuedTo: accessKey?.issuedTo || '',
      limitPerUse: accessKey?.limitPerUse ?? 0,
      product: {
        audience: accessKey?.product?.audience || '',
        title: accessKey?.product?.title || '',
        vendorProductCode: accessKey?.product?.vendorProductCode || '',
      },
      productCode: accessKey?.productCode || '',
      termsInDays: accessKey?.termsInDays ?? 0,
      vendor: accessKey?.vendor || '',
    },
    onSubmit: async (values) => {
      if (account?.permissions?.editAccessKeys) {
        try {
          await updateAccessKey(values);
          setIsEditing(false);
          popToast('Access key successfully updated.');
        } catch (error) {
          popToast(
            (error as IError)?.message ||
              'An unexpected error occurred. Please try again later.',
          );
        }
      } else {
        popToast('You do not have permission to perform this action.');
      }
    },
    validateOnChange: false,
    validationSchema,
  });

  return (
    <>
      <EditableContent
        canEdit={
          (account?.permissions?.editAccessKeys &&
            accessKey?.redemptions?.length === 0) ??
          false
        }
        isEditing={isEditing}
        onCancel={() => {
          formik.resetForm();
          setIsEditing(false);
        }}
        onEdit={() => setIsEditing(true)}
        formik={formik}
        title="Access Key"
      >
        <Card className={styles.grid}>
          <EditableDetail
            containerClassName={styles.key}
            label="Access Key"
            name="accessKey"
            required
          />
          <EditableDetail
            containerClassName={styles.title}
            label="Product Title"
            name="product.title"
            required
          />
          <EditableDetail
            label="Vendor"
            name="vendor"
            renderValue={(value = '') => getEnumKeyByEnumValue(Vendors, value)}
            required
            select
          >
            <option value={Vendors.BenchPrep}>{VendorNames.BenchPrep}</option>
            <option value={Vendors.Gutenberg}>{VendorNames.Gutenberg}</option>
            <option value={Vendors.Populi}>{VendorNames.Populi}</option>
            <option value={Vendors.Skillable}>{VendorNames.Skillable}</option>
          </EditableDetail>
          <EditableSwitch
            label="Status"
            name="active"
            renderValue={(value) => (value ? 'Active' : 'Inactive')}
          />
          <EditableDetail
            label="Vendor Product Code"
            name="product.vendorProductCode"
            required
          />
          <EditableSwitch
            label="Is Certmaster CE"
            name="isCertmasterCE"
            renderValue={(value) => (value ? 'Yes' : 'No')}
          />
          <EditableDetail
            label="CompTIA Product Code"
            name="productCode"
            required
          />
          <Detail
            label="Redemptions"
            value={`${accessKey?.redemptions?.length ?? 0}/${
              formik.values.limitPerUse
            }`}
          />
          <EditableDetail label="CompTIA Issued To" name="issuedTo" />
          <EditableDetail
            label="Limit Per Use"
            name="limitPerUse"
            required
            type={TextFieldTypes.Number}
          />
          <EditableDetail
            label="Entitlement Term Days"
            name="termsInDays"
            required
          />
          <EditableDetail
            label="Product Audience"
            name="product.audience"
            required
          />
          <Detail label="Creation Date" />
          <div />
          <EditableCalendar label="Expiration Date" name="expirationDate" />
        </Card>
      </EditableContent>
      <LoadingOverlay isOpen={formik.isSubmitting} text="Updating Access Key" />
    </>
  );
};
